import Web3 from 'web3'
import { isNumber } from 'lodash-es'

export type ChainType = 'ftm'

const EMPIRELAND_SALE_CONTRACT_MAIN = '0x7Ab8B963891191b8fDc99Aa1796B3F30ef0B32d5'
const EMPIRELAND_SALE_CONTRACT_TEST = '0x7Ab8B963891191b8fDc99Aa1796B3F30ef0B32d5'
const EMPIRELAND_BOX_CONTRACT_MAIN = '0x4158f49fCfE117f36e0d678AE3A6B80dA25eC50D'
const EMPIRELAND_BOX_CONTRACT_TEST = '0x4158f49fCfE117f36e0d678AE3A6B80dA25eC50D'

const getChainConfig = (chainId: any) => {
  chainId = isNumber(+chainId) ? +chainId : chainId
  let rpc = ''
  let name = ''
  switch (chainId) {
    case 250:
    case 'ftm':
      name = 'Fantom MainNET'
      rpc = 'https://rpc.ftm.tools/'
      break
    case 4002:
      name = 'Fantom TestNET'
      rpc = 'https://xapi.testnet.fantom.network/lachesis'
      break
  }
  return { rpc, name }
}

const getWeb3 = (chainId: any) => {
  chainId = isNumber(+chainId) ? +chainId : chainId
  const { rpc, name } = getChainConfig(chainId)
  if (rpc) return new Web3(new Web3.providers.HttpProvider(rpc))
  else return null
}

const cachedContracts: { [id: string]: any } = {}

// const empirelandSaleFactory = (model: any) => {
//   const { chainId } = model
//   let result: any = undefined
//   let contractAddress = model.contractAddress
//   if (!contractAddress) {
//     if (+chainId === 250) {
//       contractAddress = EMPIRELAND_SALE_CONTRACT_MAIN
//     } else if (+chainId == 4002) {
//       contractAddress = EMPIRELAND_SALE_CONTRACT_TEST
//     }
//   }
//   if (contractAddress) {
//     result = cachedContracts[contractAddress]
//     if (!result) {
//       result = new EmpirelandSaleHandler(contractAddress, getWeb3(chainId)!)
//       result.injectProvider()
//       cachedContracts[contractAddress] = result
//     } else {
//       result.injectProvider()
//     }
//   }
//   return result
// }

function etherBatchRequest(web3: Web3, methods: any[]) {
  if (!methods.length) return []
  const batch = new web3.BatchRequest()
  const tasks = Promise.all(
    methods.map(
      (method) =>
        new Promise((resolve, reject) => {
          batch.add(
            method.call.request({}, function (error, result) {
              if (error) {
                console.error('Errror=', method, error)
                reject(error)
              } else {
                resolve(result)
              }
            })
          )
        })
    )
  )
  batch.execute()
  return tasks
}

export const blockchainHandler = {
  getChainConfig,
  getWeb3,
  etherBatchRequest,
  // empirelandSaleFactory,
}
